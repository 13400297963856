import React, {useMemo, useRef, useState} from "react";
import {Button, Col, Container, FloatingLabel, Form, FormSelect, InputGroup, Row} from "react-bootstrap";
import api from "./api";
import {useDispatch, useSelector} from "react-redux";
import KontrachentView from "./KontrachentView";
import {kwotaStr} from "./Faktury";
import {addKontrachent} from "./store";
import SuggestionsInput from "./SuggestionsInput";

const num2inputDate = num=>{
    return parseInt((num+'').substring(6,8))+"."+(num+'').substring(4,6)+"."+(num+'').substring(0,4);
}

const inputDate2num = str=>{
    if(!str)
        return null;
    let mm = str.match(/(\d+)[-|\.](\d+)[-|\.](\d+)/);
    if(!mm)
        return false;
    let [,d,m,y] = mm;
    if(d.length === 4)
    {
        const yy = d;
        d = y;
        y = yy;
    }
    if(y.length !== 4 || d.length > 2 || m.length > 2 || parseInt(d) > 31 || parseInt(m) > 12 || parseInt(d) < 1 || parseInt(m) < 1)
        return false;
    //return parseInt(str.substring(0,4)+str.substring(5,7)+str.substring(8,10));
    return y+(m.length===1?'0':'')+m+(d.length===1?'0':'')+d;
}

const FakturaView = ({f,onClose,displMonths})=>{

    const {for_month} = useSelector(state=>state.filter);

    const kontrachenci = useSelector(state=>state.kontrachenci);
    const kategorie = useSelector(state=>state.kategorie);
    const [curData,setCurData] = useState({...f,for_month:f.for_month||for_month});
    const kontrachenci_ids = useMemo(()=>{
        if(!kontrachenci)
            return [];

        return Object.keys(kontrachenci).sort((k1,k2)=>{
            const nazwa1 = kontrachenci[k1].nazwa.toUpperCase();
            const nazwa2 = kontrachenci[k2].nazwa.toUpperCase();
            if(nazwa1 < nazwa2)
                return -1;
            if(nazwa1 > nazwa2)
                return 1;
            return 0;
        });
    }, [kontrachenci]);
    const canSave = curData.duedate!==false&&curData.kontrachent_id&&curData.numer&&curData.kwota&&(!f.faktura_id||JSON.stringify(f)!=JSON.stringify(curData));
    const [viewKontrachent,setViewKontrachent] = useState(null);
    const [dueDateFocus,setDueDateFocus] = useState(false);
    const [amountFocus,setAmountFocus] = useState(false);
    const [kontrachent,setKontrachent] = useState(()=>curData.kontrachent_id?kontrachenci[curData.kontrachent_id].nazwa:"");
    const dispatch = useDispatch();
    const [dueDate,setDueDate] = useState(()=>curData.duedate?num2inputDate(curData.duedate):"");
    const [dueDateYearAdded,setDueDateYearAdded] = useState(false);

    //console.log("isValid",dueDateFocus?undefined:(curData.duedate === false ? false : (curData.duedate?true:undefined)));

    return (
        <div id={"faktura-view"}
             onClick={onClose}>
            <iframe src={f.viewUrl}/>
            <div id={"faktura-side"} onClick={ev=>{
                ev.stopPropagation();
            }}>
                <Container fluid className={"h-100"}>
                    {viewKontrachent===null &&
                    <div className={"d-flex flex-column h-100"}>
                        <Form>
                                <FloatingLabel label={"Kontrahent"} className={"mb-3"}>
                                    <SuggestionsInput value={kontrachent}
                                                      list={kontrachenci_ids.map(id=>kontrachenci[id].nazwa)}
                                                      onChangeVal={k=>{
                                                          let kontrachent_id;
                                                          for(const ids of kontrachenci_ids)
                                                              if(kontrachenci[ids].nazwa === k){
                                                                  kontrachent_id = parseInt(ids);
                                                                  break;
                                                              }
                                                          if(!kontrachent_id && k.trim())
                                                              kontrachent_id = -1; // new
                                                          setCurData({...curData,kontrachent_id});
                                                          setKontrachent(k);
                                                        }
                                                      }
                                    />
                                </FloatingLabel>
                        <FloatingLabel className="mb-3" label={"Numer"}>
                            <Form.Control type={"text"}
                                          defaultValue={curData.numer||""}
                                          onChange={ev=>setCurData({...curData,numer:ev.target.value})}/>
                        </FloatingLabel>
                        <Row className={"mb-3"}>
                            <Col>
                                <FloatingLabel label={"Kwota"}>
                                    <Form.Control type={"text"}
                                                  onFocus={()=>setAmountFocus(true)}
                                                  onBlur={()=>setAmountFocus(false)}
                                                  isInvalid={!amountFocus&&curData.kwota === false}
                                                  defaultValue={typeof curData.kwota === 'number' ? kwotaStr(curData.kwota) : ""}
                                                  onChange={ev=>{
                                                      let v = ev.target.value;
                                                      if(/^\d+(,\d\d\d)+(\.\d\d)?$/.test(v)){
                                                          v = v.replace(/,/g,"");
                                                      }
                                                      else
                                                      if(/^\d+(\.\d\d\d)+(,\d\d)?$/.test(v)){
                                                          v = v.replace(/\./g,"");
                                                          v = v.replace(',',".");
                                                      }
                                                      else
                                                      if(/^\d+(\s\d\d\d)+([,|.]\d\d)?$/.test(v)){
                                                          v = v.replace(/\s/g,"");
                                                          v = v.replace(',',".");
                                                      }
                                                      else
                                                      if(/^\d+([,|.]\d\d)?$/.test(v)){
                                                          v = v.replace(',',".");
                                                      }
                                                      let kwota;
                                                      if(!/^\d+(\.\d\d)?$/.test(v))
                                                          kwota = false;
                                                      else
                                                          kwota = v==='' ? '' : parseFloat(v);
                                                      setCurData({...curData,kwota})
                                                  }}/>
                                </FloatingLabel>
                            </Col>
                            <Col xs={"auto"}>
                                <FloatingLabel label={"Płatność"}>
                                    <Form.Select defaultValue={curData.payment||"transfer"} onChange={ev=>{
                                            setCurData({...curData,payment:ev.target.value});
                                        }
                                    }>
                                        <option value={"transfer"}>Przelew</option>
                                        <option value={"cash"}>Gotówka</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col>
                                <FloatingLabel label={"Termin płatności"}>
                                    <Form.Control type={"text"}
                                                  onFocus={()=>setDueDateFocus(true)}
                                                  onBlur={()=>{
                                                      setDueDateFocus(false);
                                                      if(!curData.duedate){
                                                          const curDueDate = inputDate2num(dueDate+"."+new Date().getFullYear());
                                                          if(curDueDate){
                                                              setCurData({...curData,duedate:curDueDate});
                                                              setDueDate(num2inputDate(curDueDate));
                                                          }
                                                      }
                                                  }}
                                                  isInvalid={!dueDateFocus&&curData.duedate === false}
                                                    //defaultValue={curData.duedate?num2inputDate(curData.duedate):""}
                                                value={dueDate}
                                                  onChange={ev=>{
                                                      let curDueDate = inputDate2num(ev.target.value);
                                                      if(!curDueDate && !dueDateYearAdded){
                                                          curDueDate = inputDate2num(ev.target.value+new Date().getFullYear());
                                                          if(curDueDate)
                                                              setDueDateYearAdded(true);
                                                      }
                                                      setCurData({...curData,duedate:curDueDate});
                                                      setDueDate(curDueDate?num2inputDate(curDueDate):ev.target.value);
                                                  }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={"auto"}>
                                <FloatingLabel label={"Zapłacona"} onClick={()=>setCurData({...curData,ispaid:!curData.ispaid})}>
                                    <div className={"form-control"}>
                                        {curData.ispaid ?
                                            <div className={"faktura-paid-icon"}><i className={"bi-check"}/></div> :
                                            <div className={"faktura-notpaid-icon"}><i className={"bi-dash"}/></div>
                                        }
                                    </div>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col>
                                <FloatingLabel label={"Kategoria"}>
                                    <FormSelect defaultValue={curData.kat_id||"none"} onChange={ev=>{
                                        let kat_id = ev.target.value;
                                        if(kat_id === "none")
                                            kat_id = null;
                                        else
                                            kat_id = parseInt(kat_id);
                                        setCurData({...curData,kat_id});
                                    }}>
                                        <option value={"none"}>ogólne</option>
                                        {Object.keys(kategorie).map((kat_id,idx)=>
                                            (<option value={kat_id} key={kat_id}>{kategorie[kat_id].nazwa}</option>))
                                        })
                                    </FormSelect>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col>
                                <FloatingLabel className="mb-3" label={"Opis"}>
                                    <Form.Control type={"text"}
                                                  defaultValue={curData.opis||""}
                                                  onChange={ev=>setCurData({...curData,opis:ev.target.value})}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={"auto"}>
                                <Form.Select defaultValue={curData.for_month} onChange={ev=>{
                                    setCurData({...curData,for_month: parseInt(ev.target.value)});
                                }}>
                                    {displMonths.map((month,idx)=><option key={idx} value={month.num}>{month.name}</option>)}
                                </Form.Select>
                            </Col>
                            <Col/>
                            <Col xs={"auto"}>
                            <Button variant="primary" disabled={!canSave}
                                    onClick={async ()=>{
                                        let {faktura_id,kat_id=null,ispaid,duedate,kontrachent_id,numer,kwota,opis,payment,for_month} = curData;

                                        if(kontrachent_id === -1){
                                            const res = await api("modify_kontrachent",{
                                                kontrachent_id:null,
                                                kontrachent:{nazwa:kontrachent}
                                            });
                                            console.log("modify_kontrachent2",res.status,res.status === "success",res,kontrachent,kontrachent_id||res.kontrachent_id);
                                            if(res.status === "success"){
                                                kontrachent_id = res.kontrachent_id;
                                                dispatch(addKontrachent({
                                                    kontrachent_id,
                                                    kontrachent:{nazwa:kontrachent}
                                                }));
                                                setCurData({curData,...kontrachent_id});
                                                //setSuggs([]);
                                            }
                                        }
                                        if(faktura_id)
                                            return api("modify_faktura",{
                                                faktura_id,
                                                kontrachent_id,
                                                kat_id,
                                                for_month,
                                                ispaid:ispaid||false,
                                                duedate:duedate||null,
                                                numer:numer||"",
                                                kwota:kwota||"",
                                                opis:opis||"",
                                                payment
                                            }).then(()=>onClose(true));

                                        const xhr = new XMLHttpRequest();
                                        const fd = new FormData();

                                        xhr.open("POST", "/faktura", true);
                                        xhr.onreadystatechange = function() {
                                            if (xhr.readyState == 4 && xhr.status == 200) {
                                                if(xhr.responseText === "uploaded")
                                                    return onClose(true);
                                                alert(xhr.responseText); // handle response.
                                            }
                                        };
                                        if(f.file){
                                            fd.append('faktura', f.file);
                                            // Initiate a multipart/form-data upload
                                        }
                                        if(f.id)
                                            fd.append("id",f.id);
                                        fd.append("for_month",for_month);
                                        fd.append("kat_id",kat_id||0);
                                        fd.append("kontrachent_id",kontrachent_id);
                                        fd.append("numer",numer||"");
                                        fd.append("ispaid",ispaid?1:0);
                                        if(duedate)
                                            fd.append("duedate",duedate);
                                        fd.append("kwota",kwota||"");
                                        fd.append("opis",opis||"");
                                        fd.append("payment",payment||"");
                                        xhr.send(fd);
                                    }}
                            >
                                Zapisz</Button>
                            </Col>
                        </Row>
                    </Form>
                        <div style={{flex:1}}/>
                        {curData.faktura_id && <div className={"clearfix faktura-delete-button"} onClick={()=>{
                            return api("delete_faktura",{
                                faktura_id:curData.faktura_id
                            }).then(()=>onClose(true));
                        }}>
                            Usuń fakturę
                        </div>}
                    </div>
                    }
                    {viewKontrachent !== null &&
                        <KontrachentView kontrachent_id={viewKontrachent} onClose={(kontrachent_id)=>{
                            setViewKontrachent(null);
                            if(kontrachent_id)
                                setCurData({...curData,kontrachent_id});
                        }}/>
                    }
                </Container>
            </div>
        </div>
    );
}

export default FakturaView;