import { configureStore,createSlice } from '@reduxjs/toolkit'


const loggedSlice = createSlice({
    name: 'logged',
    initialState: {
        logged:null,
        //for_month:new Date().getMonth()+1,
        firmy:[],
        firma:null
    },
    reducers: {
        setLogged: (state,action) => {
            state.logged = action.payload;
        },
        //setForMonth: (state,action) => {
        //    state.for_month = action.payload;
        //},
        setFirmy: (state,action)=>{
            state.firmy = action.payload;
        },
        setFirmaId: (state,action)=>{
            state.firma_id = action.payload;
        },
        //setFilterKat: (state, action)=>{
        //    state.filter_kat = action.payload;
        //}

    },
})

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        for_month:new Date().getMonth()+1,
    },
    reducers: {
        setForMonth: (state,action) => {
            console.log("setForMonth");
            return {for_month:action.payload};
        },
        setFilter: (state, action)=>{
            return {...action.payload,for_month:state.for_month};
        }
    },
})



const fakturySlice = createSlice({
    name: 'faktury',
    initialState: [],
    reducers: {
        setFaktury: (state,action) => {
            return action.payload;
        },
    },
})

const kategorieSlice = createSlice({
    name: 'kategorie',
    initialState: {},
    reducers: {
        setKategorie: (state,action) => {
            return action.payload;
        },
    },
})



const kontrachenciSlice = createSlice({
    name: 'kontrachenci',
    initialState: {},
    reducers: {
        setKontrachenci: (state,action) => {
            return action.payload;
        },
        addKontrachent:(state,action)=>{
            const {kontrachent_id,kontrachent} = action.payload;
            console.log("addKontrachent",kontrachent_id,kontrachent);
            state[kontrachent_id] = kontrachent;
        },
        delKontrachenci:(state,action)=>{
            action.payload.forEach(kontrachent_id=>{
                delete state[kontrachent_id];
            });
        },
    },
})

export const { setFaktury } = fakturySlice.actions
export const { setLogged,setFirmy,setFirmaId } = loggedSlice.actions
export const { setKontrachenci,addKontrachent,delKontrachenci } = kontrachenciSlice.actions
export const { setKategorie } = kategorieSlice.actions
export const { setForMonth,setFilter } = filterSlice.actions


export default configureStore({
    reducer: {filter:filterSlice.reducer,kontrachenci:kontrachenciSlice.reducer,logged:loggedSlice.reducer,faktury:fakturySlice.reducer,kategorie:kategorieSlice.reducer},
})