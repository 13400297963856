const api = async (req,params={})=>{
    let res;
    let resHttp = await fetch("/api", {
        method:"POST",
        body: JSON.stringify({req,params}),
        headers: { 'Content-Type': 'application/json' }
    });
    if(!resHttp)
    {
        console.log("fetch returned null");
        res = null;
    }
    else
    if(resHttp.status !== 200){
        console.log("fetch status not 200",resHttp.status);
        res = null;
    }
    else{
        res = await resHttp.json();
        if(res.status === "not_logged")
            // eslint-disable-next-line no-restricted-globals
            location.reload();
    }
    return res;
}


export default api;