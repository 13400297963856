import Faktury, {fetchFaktury, kwotaStr} from "./Faktury";
import { Typeahead } from 'react-bootstrap-typeahead';
import {
    Button,
    Col,
    Container,
    Form,
    FormCheck,
    FormControl,
    FormSelect,
    InputGroup,
    Nav,
    Navbar,
    Row
} from "react-bootstrap";
import FakturaView from "./FakturaView";
import DragDest from "./DragDest";
import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setFilter, setForMonth} from "./store";
import SuggestionsInput from "./SuggestionsInput";

const months = ["Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec","Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień"];


const FakturyView = ()=>{

    const kontrachenci = useSelector(state=>state.kontrachenci);
    const {for_month,filter_kat,filter_kon,filter_unpaid} = useSelector(state=>state.filter);
    const dispatch = useDispatch();
    const [viewedFaktura,setViewedFaktura] = useState();
    const faktury = useSelector(state=>state.faktury);
    const kategorie = useSelector(state=>state.kategorie);
    const [kontrachent,setKontrachent] = useState(()=>filter_kon && kontrachenci[filter_kon] ? kontrachenci[filter_kon].nazwa : "");
    const kontrachentFilterRef = useRef();

    const kat_filters = useMemo(()=>{
        const filt = {};
        const add = (name,parent)=>{
            const pp = parent?parseInt(parent):0;
            let all = [pp];
            for(const kat_id in kategorie){
                if((kategorie[kat_id].parent||0) === pp){
                    if(all.length === 1){
                        filt[name+(name?" / ":"")+(name?"*":"wszystkie")] = all;
                        filt[name+(name?" / ":"")+"ogólne"] = pp;
                    }
                    let name2 = name;
                    if(name2)
                        name2 += " / ";
                    name2 += kategorie[kat_id].nazwa1;
                    const _all = add(name2,kat_id);
                    for(let i=0; i<_all.length; i++)
                        all.push(_all[i]);
                }
            }
            if(all.length === 1){
                filt[name||"wszystkie"] = pp;
            }
            return all;
        };
        add("",null);
        filt["wszystkie"] = undefined;
        return filt;
    }, [kategorie]);

    const kat_filter = useMemo(()=>{
        for(let kat_filter in kat_filters)
            if(JSON.stringify(kat_filters[kat_filter]) === JSON.stringify(filter_kat))
                return kat_filter;
        dispatch(setFilter({}));
        return null;
    }, [kat_filters,filter_kat]);

    const kon_filter = useMemo(()=>{
        if(!filter_kon || kontrachenci[filter_kon])
            return filter_kon;
        dispatch(setFilter({}));
        return null;
    }, [kontrachenci,filter_kon]);


    /*useEffect(()=>{
        api("get_kontrachenci",{firma_id}).then(res=>{
            console.log("kontrachenci",res.kontrachenci);
            dispatch(setKontrachenci(res.kontrachenci));
        });
    }, [firma_id]);*/
    const suma = useMemo(()=>{
        let sum = 0;
        faktury && faktury.forEach(({kwota})=>{
            sum += parseFloat(kwota);
        });
        return sum;
    }, [faktury]);


    const handleFiles = files=>{
        if(files.length > 0){
            const file = files[0];
            const {type} = file;
            if(type === 'application/pdf' || type.match(/^image\/\w+/))
                setViewedFaktura({
                    file,
                    viewUrl:URL.createObjectURL(files[0])});
        }
    }



    const inputElem = useRef();

    const curMonth = new Date().getMonth();
    const displMonths = useMemo(()=>{
        const monthsIdx = months.map((name,idx)=>({
            name,
            num:idx+1
        }));
        return monthsIdx.slice(0,curMonth+1).reverse().concat(monthsIdx.slice(curMonth+1).reverse());
    }, [curMonth]);


    const kontrachenci_s = useMemo(()=>{
        if(!kontrachenci)
            return [];
        const k = Object.keys(kontrachenci).map(k_id=>{
            return {
                id:k_id,
                nazwa:kontrachenci[k_id].nazwa
            }
        });
        return k.sort((k1,k2)=>{
            if(k1.nazwa < k2.nazwa)
                return -1;
            if(k1.nazwa > k2.nazwa)
                return 1;
            return 0;
        });
    }, [kontrachenci]);

    const kontrachenci_nazwy = useMemo(()=>kontrachenci_s.map(({nazwa})=>nazwa), [kontrachenci_s]);
    const kontrachenci_idx = useMemo(()=>{
        const kidx = {};
        for(const k_id in kontrachenci)
            kidx[kontrachenci[k_id].nazwa] = k_id;
        return kidx;
    }, [kontrachenci]);

    const filtering = typeof filter_kat !== "undefined" || typeof filter_kon !== "undefined" || filter_unpaid;


    return (
        <Fragment>
            <div className={"faktury-container"}>
                <div className={"faktury-bar"}>
                    <Form className={"d-flex me-auto"}>
                        <InputGroup className={"me-2"}>
                            <InputGroup.Text>Miesiąc</InputGroup.Text>
                            <Form.Select value={filtering?"all":for_month} disabled={filtering} onChange={ev=>{
                                if(ev.target.value !== 'all'){
                                    dispatch(setForMonth(parseInt(ev.target.value)));
                                }
                            }}>
                                {displMonths.map((month,idx)=><option key={idx} value={month.num}>{month.name}</option>)}
                                {filtering && <option value={"all"}>wszystkie</option>}
                            </Form.Select>
                        </InputGroup>
                        <div className={"faktury-back-forward"} onClick={()=>{
                            dispatch(setForMonth(for_month===displMonths[0].num?(for_month+10)%12+1:for_month%12+1));
                        }}>
                            <i className={`bi-chevron-double-${for_month===displMonths[0].num?"left":"right"}`} style={{color:"#aaa"}}/>
                        </div>
                    </Form>
                    {typeof suma !== "undefined" &&
                        <Form className={"me-4"}>
                        <InputGroup>
                            <InputGroup.Text>Suma</InputGroup.Text>
                            <div className={"form-control"}>{kwotaStr(Math.round(suma),false)} <span className={"faktury-suma-currency"}>zł</span></div>
                        </InputGroup>
                        </Form>
                            //<div className={"faktury-suma"}>Suma: {kwotaStr(Math.round(suma),false)}
                            //&nbsp;<span style={{color:"#aaa"}}>zł</span></div>
                     }
                    <Form>
                        <input type={"file"} name={"faktura"} style={{display:"none"}} accept={"image/*;application/pdf;"}
                               ref={inputElem}
                               onChange={()=>{
                                   const {files} = inputElem.current;
                                   handleFiles(files);
                               }}
                        />
                        <Button disabled={filtering} variant={"primary"} onClick={()=>inputElem.current.click()} className={"add-faktura-button"}>
                            <i className={"bi-plus-circle"}/>
                            &nbsp;
                            Dodaj fakturę
                        </Button>
                    </Form>
                </div>
                <div className={"faktury-bar"} style={{margin:"20px 0px"}}>
                    <Form className={"d-flex"}>
                        <Form.Check type={"checkbox"} value={filter_unpaid?true:false} className={"pe-4 form-control-sm"+(filter_unpaid?" faktura-filt-active":"")} label={"Niezapłacone"} onChange={(ev)=>{
                            dispatch(setFilter({filter_unpaid:ev.target.checked}));
                        }}/>
                    </Form>
                    <Form>
                        <InputGroup className={"pe-4"} size={"sm"} style={{maxWidth:300}}>
                            <InputGroup.Text>Kontrachent</InputGroup.Text>
                            <Typeahead options={kontrachenci_nazwy}
                                       open={!filter_kon && kontrachent.length > 0}
                                       clearButton={true}
                                       ref={kontrachentFilterRef}
                                       onKeyDown={ev=>{
                                           if(ev.key === 'Escape') {
                                               kontrachentFilterRef.current.clear();
                                               setKontrachent("");
                                               kontrachentFilterRef.current.blur();
                                           }
                                       }}
                                       id={"kontrachent_filtr"}
                                       align={"left"}
                                       size={"sm"}
                                       onInputChange={k=>{
                                           setKontrachent(k);
                                       }}
                                       onChange={k=>{
                                           if(kontrachenci_idx[k]){
                                               dispatch(setFilter({filter_kon:kontrachenci_idx[k]}));
                                           }
                                           else
                                               if(filter_kon)
                                                   dispatch(setFilter({}));
                                       }
                                       }
                            />
                            {false && <SuggestionsInput value={kontrachent}
                                              list={kontrachenci_nazwy}
                                              onChangeVal={k=>{
                                                  if(kontrachenci_idx[k]){
                                                      dispatch(setFilter({filter_kon:kontrachenci_idx[k]}));
                                                  }
                                                  setKontrachent(k);
                                              }
                                              }
                            />}
                            {false && <FormSelect size={"sm"} className={!kon_filter ? "" : "faktura-filt-active"} value={kon_filter||"wszystkie"}
                                        onChange={ev=>{
                                            dispatch(setFilter({filter_kon:ev.target.value==="all"?undefined:parseInt(ev.target.value)}))
                                        }}
                            >
                                <option value={"all"}>wszyscy</option>
                                {kontrachenci_s && kontrachenci_s.map((k,idx)=>{
                                    return (<option value={k.id} key={idx}>{k.nazwa}</option>);
                                })}
                            </FormSelect>}
                        </InputGroup>
                    </Form>
                    <Form>
                        <InputGroup size={"sm"}>
                            <InputGroup.Text>Kategoria</InputGroup.Text>
                            <FormSelect size={"sm"} className={kat_filter === "wszystkie"? "" : "faktura-filt-active"} value={kat_filter||"wszystkie"}
                                        onChange={ev=>{
                                            dispatch(setFilter({filter_kat:kat_filters[ev.target.value]}))
                                        }}
                            >
                                {kat_filters && Object.keys(kat_filters).map((filt,idx)=>{
                                    return (<option value={filt} key={idx}>{filt}</option>);
                                })}
                            </FormSelect>
                        </InputGroup>
                    </Form>
                </div>
                <div className={"faktury-table-container"}>
                    <Faktury setViewedFaktura={setViewedFaktura} for_month={for_month}/>
                </div>
            </div>
            {viewedFaktura && <FakturaView displMonths={displMonths} kontrachenci={kontrachenci} f={viewedFaktura} onClose={(changed)=>{
                setViewedFaktura(null);
                if(changed)
                    fetchFaktury();
            }}/>}
            {!filtering && <DragDest handleFiles={handleFiles}/>}
        </Fragment>
    )
}

export default FakturyView;