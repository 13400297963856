import {useDispatch, useSelector} from "react-redux";
import React from "react";
import api from "./api";
import {
    Container,
    FormControl,
    FormSelect, InputGroup, Nav, Navbar, NavDropdown,
} from "react-bootstrap";
import {setFirmaId, setLogged} from "./store";
import FakturaIcon from "./img/faktura.png"
import FakturyView from "./FakturyView";

const SIDEBAR = false;

function Main() {

    const {firmy,firma_id} = useSelector(state=>state.logged);
    const dispatch = useDispatch();

    if(SIDEBAR)
        return (
            <div className={"main"}>
                <div className={"main-sidebar"}>
                    <img src={FakturaIcon} className={"main-sidebar-logo"}/>
                    <div className={"main-sidebar-info"}>
                        {firmy.length === 1 &&
                        <FormControl type={"text"} value={firmy.find(f => f.firma_id === firma_id).nazwa}
                                     disabled={true}/>
                        }
                        {firmy.length > 1 &&
                        <FormSelect value={firma_id} onChange={ev=>{
                            dispatch(setFirmaId(parseInt(ev.target.value)));
                        }
                        }>
                            {firmy.map(({nazwa,firma_id},idx)=><option key={idx} value={firma_id}>{nazwa}</option>)}
                        </FormSelect>
                        }
                    </div>
                    <div className={"main-sidebar-menu"}>
                        <div className={"active"}>Faktury</div>
                        <div onClick={async ()=>{
                            await api("logout");
                            dispatch(setLogged(false));
                        }}>Wyloguj</div>
                    </div>
                </div>
                <div className={"main-content"}>
                    <FakturyView/>
                </div>
            </div>
        );
    else
        return (
            <div className={"main-container"}>
                <Navbar className={"main-toolbar"}>
                    <Container fluid>
                        <Nav>
                            <Navbar.Brand><img src={FakturaIcon} style={{height:30}}/></Navbar.Brand>
                        </Nav>
                        <Nav>
                            {firmy.length === 1 &&
                            <FormControl type={"text"} value={firmy.find(f => f.firma_id === firma_id).nazwa}
                                         disabled={true}/>
                            }
                            {firmy.length > 1 &&
                            <FormSelect value={firma_id} onChange={ev=>{
                                dispatch(setFirmaId(parseInt(ev.target.value)));
                            }
                            }>
                                {firmy.map(({nazwa,firma_id},idx)=><option key={idx} value={firma_id}>{nazwa}</option>)}
                            </FormSelect>
                            }
                            <NavDropdown className={"user-menu"} title={<i className={"bi-person"} style={{fontSize:20}}/>}>
                                <NavDropdown.Item onClick={async ()=>{
                                    await api("logout");
                                    dispatch(setLogged(false));
                                }}>Wyloguj</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Container>
                </Navbar>
                <div className={"main-content"}>
                    <FakturyView/>
                </div>
            </div>
        );
}

export default Main;