import {Container, Button, Form, FormControl, InputGroup} from "react-bootstrap";
import React, {useRef, useState} from "react";
import api from "./api";


const Login = ()=>{

    const [userName,setUserName] = useState("");
    const [password,setPassword] = useState("");
    const [validating,setValidating] = useState(false);
    const [permanentLogin,setPermanentLogin] = useState(false);

    return (
      <Container fluid>
          <div className={"login-container"}>
              <div className={"login"}>
                  <Form>
                      <h5 className={"mb-3"}>Logowanie</h5>
                      <InputGroup className="mb-3">
                          <InputGroup.Text><i className={"bi-person-fill"}/></InputGroup.Text>
                          <FormControl
                              placeholder="Użytkownik (email)"
                              name={"user"}
                              onChange={ev=>setUserName(ev.target.value)}
                          />
                      </InputGroup>
                      <InputGroup className="mb-3">
                          <InputGroup.Text><i className={"bi-key-fill"}/></InputGroup.Text>
                          <FormControl
                              name={"password"}
                              type={"password"}
                              placeholder={"Hasło"}
                              onChange={ev=>setPassword(ev.target.value)}
                          />
                      </InputGroup>
                      <Form.Check
                          className="mb-3"
                          defaultValue={permanentLogin}
                          onChange={ev=>{
                              setPermanentLogin(ev.target.checked);
                          }}
                          type={"checkbox"}
                          label={`Zapamiętaj na tym urządzeniu`}
                      />
                      <Button variant="primary"
                              disabled={validating||!(userName&&/\w+@\w+/.test(userName))} className={"float-end"}
                              onClick={()=>{
                                  setValidating(true);
                                  api("login",{userName,password,permanentLogin}).then((res)=>{
                                      if(res.logged === true){
                                          //dispatch(setLogged(true));
                                          // eslint-disable-next-line no-restricted-globals
                                          location.reload();
                                      }
                                      else
                                          setValidating(false);
                                  });
                              }}
                      >Zaloguj się</Button>
                  </Form>
              </div>
          </div>
      </Container>
    );
}

export default Login;