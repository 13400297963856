import React, {Fragment, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import './css/suggestions.css'

const SuggestionsInput = ({value,onChangeVal,list})=>{
    const [hasFocus,setHasFocus] = useState(false);
    const [suggs,setSuggs] = useState([]);


    return <Fragment>
        <Form.Control type={"text"}
                      value={value}
                      onFocus={()=>setHasFocus(true)}
                      onBlur={()=>{
                          // enable clicking suggestions
                          setTimeout(()=>{
                              setHasFocus(false)
                          }, 200);
                      }}
                      onKeyDown={ev=>{
                        }
                      }
                      onChange={ev=>{
                          const k = ev.target.value;
                          const suggs = [];
                          if(list.indexOf(k) < 0){
                              if(k.trim()){
                                  if(k.length > 1)
                                      list.forEach(val=>{
                                          if(val.toLowerCase().indexOf(k.toLowerCase()) >= 0){
                                              suggs.push(val);
                                          }
                                      });
                              }
                          }
                          setSuggs(suggs);
                          setHasFocus(true);
                          onChangeVal(k);
                      }}
        >
        </Form.Control>
        {suggs.length > 0 && suggs.length <= 10 && hasFocus &&
        <div className={"suggestions-list"}>
            {suggs.map((sugg,idx)=>
                <div className={"suggestions-element"}

                     key={idx}
                     onClick={()=>{
                         console.log("click suggs");
                         setSuggs([]);
                         onChangeVal(sugg);
                     }}
                >{sugg}</div>)}
        </div>}
    </Fragment>
}

export default SuggestionsInput;