import React, {useEffect, useState} from "react";

const DragDest = ({handleFiles})=>{
    const [dragging,setDragging] = useState(false);

    useEffect(()=>{

        function dragenter(e) {
            setDragging(true);
            e.stopPropagation();
            e.preventDefault();
        }

        function dragover(e) {
            setDragging(true);
            e.stopPropagation();
            e.preventDefault();
        }

        function dragleave(e){
            setDragging(false);
            e.stopPropagation();
            e.preventDefault();
        }

        function dragend(e){
            setDragging(false);
            e.stopPropagation();
            e.preventDefault();
        }

        function drop(e) {
            setDragging(false);
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            const files = dt.files;

            handleFiles(files);
        }

        window.addEventListener("dragenter", dragenter, false);
        window.addEventListener("dragover", dragover, false);
        window.addEventListener("dragleave", dragleave, false);
        window.addEventListener("dragend", dragend, false);
        window.addEventListener("drop", drop, false);

        return ()=>{
            window.removeEventListener("dragenter",dragenter);
            window.removeEventListener("dragover",dragover);
            window.removeEventListener("dragleave", dragleave);
            window.removeEventListener("dragend", dragend);
            window.removeEventListener("drop",drop);
        }

    }, [window]);

    return !dragging?null:<div className={"drag-dest"}><div>Przeciągnij i upuść plik z fakturą, aby ją dodać</div></div>;
}

export default DragDest;