import React, {useEffect} from "react";
import "./App.css";
import Main from "./Main";
import Login from "./Login";
import api from "./api";
import {useDispatch, useSelector} from "react-redux";
import {setForMonth, setLogged, setFirmy, setFirmaId } from "./store";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';


function App() {

    const {logged} = useSelector(state=>state.logged);
    const dispatch = useDispatch();

    useEffect(()=>{
        api("is_logged").then(res=>{
            console.log("is_logged res:",res);
            if(res.logged){
                if(res.for_month)
                    dispatch(setForMonth(res.for_month));
                dispatch(setFirmy(res.firmy));
                dispatch(setFirmaId(res.firma_id));
            }
            dispatch(setLogged(!!res.logged));
        });
    }, []);

    return logged === true ? <Main/> :
        logged === false ? <Login/> :
            null;
}

export default App;
