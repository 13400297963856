import {Container, FormSelect, Table} from "react-bootstrap";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import api from "./api";
import {useDispatch, useSelector} from "react-redux";
import FakturaIcon from "./img/faktura.png"
import "./Faktury.css"
import {addKontrachent, delKontrachenci, setFilterKat, setKategorie, setKontrachenci} from "./store";
import store,{setFaktury} from "./store";


const kwotaStr = (k,gr)=>{
    let res = ((Math.abs(k)*100)%100).toFixed(0);
    if(res.length < 2)
        res = '0'+res;
    let ks = k<0?"-":"";
    k = parseInt(Math.abs(k));
    if(k >= 1000)
    {
        ks += Math.floor(k/1000)+' ';
        k = k % 1000;
        if(k<10)
            ks+='0';
        if(k<100)
            ks+='0';
        ks += k;
    }
    else
        ks += k+'';
    return ks+(typeof gr==="undefined"?","+res:"");
}

const getDueDays = duedate=>{
    const inputDate2date = str=>{
        return new Date(parseInt(str.substring(0,4)),parseInt(str.substring(4,6))-1,parseInt(str.substring(6,8)),15);
    }

    const cur = new Date();
    return Math.trunc(inputDate2date(duedate+'').getTime()/(1000*60*60*24)) -
        Math.trunc(cur.getTime()/(1000*60*60*24)) || 0;


    //return Math.trunc((inputDate2date(duedate+'').getTime() - cur.getTime())/(1000*60*60*24))||0; // prevent -0
}

const DueDays = ({duedate})=>{
    const days = getDueDays(duedate);

    return <span className={`faktura-duedate-${days===0?"today":(days>0?"before":"after")}`}>{days === 0 ? "dzisiaj" : `${days} dni`}</span>;
}

const dateStr = date=>{
    const s = new Date(date).toLocaleDateString();
    const today = new Date().toLocaleDateString();
    const yesterday = new Date(new Date().getTime()-24*60*60*1000).toLocaleDateString();
    return s===today?"dzisiaj":(s===yesterday?"wczoraj":s);
}

const fetchFaktury = (/*for_month,firma_id,dispatch*/)=>{
    const {firma_id} = store.getState().logged;
    const {for_month,filter_kat,filter_kon,filter_unpaid} = store.getState().filter;
    const {dispatch} = store;
    console.log("filter_kat",filter_kat);
    api("get_faktury",{for_month,firma_id,filter_kat,filter_unpaid,filter_kon}).then(res=>{
        console.log("get_faktury",res);
        if(res.status !== "success")
            return dispatch(setFaktury([]));
        res.faktury.forEach(f=>{
            f.viewUrl = (process.env.NODE_ENV==='development'?"http://litsrv.foltyn.com:3001":"")+f.viewUrl;
        });
        res.faktury.sort((f1,f2)=>{
            if(filter_unpaid){
                const d1 = getDueDays(f1.duedate);
                const d2 = getDueDays(f2.duedate);
                return d1 < d2 ? -1 : (d1 > d2 ? 1 : 0);
            }
            if(!filter_kat && !filter_kon){
                if(f1.for_month < f2.for_month)
                    return 1;
                if(f1.for_month > f2.for_month)
                    return -1;
            }
            const d1 = new Date(f1.created).getTime();
            const d2 = new Date(f2.created).getTime();
            if(d1 < d2)
                return 1;
            if(d1 > d2)
                return -1;
            return 0;
        });
        dispatch(setFaktury(res.faktury));
        res.kontrachenci && dispatch(setKontrachenci(res.kontrachenci));
        if(res.kategorie){
            const kategorie = {};
            const add = (name,parent)=>{
                for(const kat_id in res.kategorie){
                    if(res.kategorie[kat_id].parent === (parent?parseInt(parent):null)){
                        let name2 = name;
                        if(name2)
                            name2 += " / ";
                        name2 += res.kategorie[kat_id].nazwa;
                        kategorie[kat_id] = {nazwa:name2,parent:res.kategorie[kat_id].parent,nazwa1:res.kategorie[kat_id].nazwa};
                        add(name2,kat_id);
                    }
                }
            };
            add("",null);
            dispatch(setKategorie(kategorie));
        }
    });
}

const Faktury = ({setViewedFaktura})=>{

    const kontrachenci = useSelector(state=>state.kontrachenci);
    const faktury = useSelector(state=>state.faktury);
    const {firma_id} = useSelector(state=>state.logged);
    const {for_month,filter_kat,filter_kon,filter_unpaid} = useSelector(state=>state.filter);
    const kategorie = useSelector(state=>state.kategorie);


    useEffect(()=>{

        fetchFaktury();

    }, [for_month,firma_id,filter_kat,filter_kon,filter_unpaid]);

    return (
        <Fragment>
        <div className={"faktury-table"}>
            <div className={"faktury-table-row faktury-table-header"}>
                <div className={"faktura-tabela-data-h"}>Dodano</div>
                <div>Faktura</div>
                {false && <div>Numer</div>}
                <div className={"faktura-tabela-kwota-h"}>Kwota&nbsp;&nbsp;&nbsp;</div>
                <div className={"faktura-tabela-paid-h"}>Płatność</div>
                <div>Kategoria</div>
            </div>
            <div className={"faktury-table-body"}>
            {faktury && faktury.map((f,idx)=>{
                return (<div key={idx} onDoubleClick={()=>setViewedFaktura(f)} className={"faktury-table-row"}>
                    <div className={"faktury-table-date"}>{dateStr(f.created)}</div>
                    <div className={"faktury-table-faktura"} onClick={()=>setViewedFaktura(f)}>
                        <div><div>{(kontrachenci&&kontrachenci[f.kontrachent_id]&&kontrachenci[f.kontrachent_id].nazwa)||""}</div>
                        <div className={"faktury-table-number"}>{f.numer}{f.opis?" | "+f.opis:""}</div>
                        </div>
                        <div className={"faktura-buttons"}>
                            <a href={f.viewUrl} download={f.viewUrl.substr(f.viewUrl.lastIndexOf('/') + 1)}>
                                <i className={"bi-download"}/>
                            </a>
                        </div>
                    </div>
                    {false && <div className={"faktury-table-number"}>
                        <div>
                            {false && <img src={FakturaIcon} style={{height:20,marginRight:2}} onClick={()=>setViewedFaktura(f)}/>}
                            {f.numer}
                        </div>
                        <div className={"faktura-buttons"}>
                            <i className={"bi-eye"} onClick={()=>setViewedFaktura(f)}/>
                            &nbsp;&nbsp;
                            <a href={f.viewUrl} download={f.viewUrl.substr(f.viewUrl.lastIndexOf('/') + 1)}>
                                <i className={"bi-download"}/>
                            </a>
                        </div>
                    </div>}
                    <div className={"faktura-tabela-kwota"}>
                         {kwotaStr(f.kwota)}
                        &nbsp;<span style={{color:"#aaa"}}>zł</span>
                    </div>
                    <div className={"faktura-tabela-paid"} onClick={()=>{
                        api("faktura_set_paid",{faktura_id:f.faktura_id,ispaid:f.ispaid?false:true}).then(()=>{
                            //f.ispaid = f.ispaid?false:true;
                            fetchFaktury();
                        });
                    }}>{f.ispaid ?
                        <div className={"faktura-paid-icon"}><i className={"bi-check"}/></div> :
                        (f.duedate ? <DueDays duedate={f.duedate}/> : <div className={"faktura-notpaid-icon"}><i className={"bi-dash"}/></div>)
                    }</div>
                    <div className={"faktura-tabela-opis"}><em>{(kategorie&&f.kat_id&&kategorie[f.kat_id])?kategorie[f.kat_id].nazwa:"ogólne"}</em></div>
                </div>);
            })}
            </div>
        </div>
        </Fragment>
    );
}

export default Faktury;
export {kwotaStr,fetchFaktury};
