import {useDispatch, useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import {Button, FloatingLabel, Form} from "react-bootstrap";
import api from "./api";
import {addKontrachent, setKontrachenci} from "./store";

const KontrachentView = ({kontrachent_id,onClose})=>{
    const kontrachenci = useSelector(state=>state.kontrachenci);
    const dispatch = useDispatch();
    const [initialKontrachent] = useState(kontrachent_id?kontrachenci[kontrachent_id]:{});
    const [dupNazwa,setDupNazwa] = useState(false);
    const [curData,setCurData] = useState({...initialKontrachent});
    const canSave = curData.nazwa&&(!kontrachent_id||JSON.stringify(initialKontrachent)!=JSON.stringify(curData));
    return (
        <Form>
            <FloatingLabel className="mb-3" label={dupNazwa?<span className={"text-danger"}>Kontrahent już istnieje</span>:"Nazwa"}>
                <Form.Control type={"text"}
                              defaultValue={curData.nazwa||""}
                              onChange={ev=>{
                                  setCurData({...curData,nazwa:ev.target.value});
                                  if(dupNazwa)
                                      setDupNazwa(false);
                              }}/>
            </FloatingLabel>
            <div>
                <Button variant="primary" disabled={!canSave} className={"float-end"} onClick={()=>{
                    const {nazwa} = curData;
                    const found = Object.keys(kontrachenci).find(idx=>kontrachenci[idx].nazwa===nazwa);
                    if(found && (!kontrachent_id||kontrachent_id!=found))
                    {
                        setDupNazwa(true);
                        setTimeout(()=>{
                            setDupNazwa(false);
                        }, 500);
                    }
                    else{
                        const kontrachent = {nazwa:curData.nazwa};
                        api("modify_kontrachent",{
                            kontrachent_id:kontrachent_id||null,
                            kontrachent
                        }).then((res)=>{
                            console.log("modify_kontrachent2",res.status,res.status === "success",res,kontrachent,kontrachent_id||res.kontrachent_id);
                            if(res.status === "success"){
                                console.log("add");
                                dispatch(addKontrachent({
                                    kontrachent_id:kontrachent_id||res.kontrachent_id,
                                    kontrachent
                                }));
                                if(!kontrachent_id)
                                    onClose(res.kontrachent_id);
                                else
                                    onClose();
                            }
                        });
                    }
                }}>
                    {!kontrachent_id?"Utwórz":"Zapisz"}
                </Button>
                <Button variant="outline-secondary" className={"float-end me-3"} onClick={()=>onClose()}>
                    Anuluj
                </Button>
            </div>
        </Form>
    )
}

export default KontrachentView;